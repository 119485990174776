import { motion } from 'framer-motion';
import useMediaQuery from '../../../../hooks/use-media-query';

export default function Devider() {
	const mobile = useMediaQuery('(max-width: 576px)');
	return (
		<motion.div
			initial={{ opacity: 0 }}
			whileInView={{ opacity: 1 }}
			transition={{ duration: 1.5 }}
			style={{ width: '100vw' }}
		>
			<svg
				version="1.0"
				xmlns="http://www.w3.org/2000/svg"
				width={mobile ? '300' : '500'}
				height="68.61924686192468"
				viewBox="0 0 896.000000 123.000000"
				preserveAspectRatio="xMidYMid meet"
			>
				<g
					transform="translate(0.000000,123.000000) scale(0.100000,-0.100000)"
					fill="#62a1d8"
					stroke="none"
				>
					<path
						d="M462 874 c-100 -26 -149 -82 -159 -179 -5 -43 -1 -63 17 -100 31 -64
71 -88 134 -81 147 16 167 215 22 219 -65 2 -101 -76 -50 -110 42 -27 69 0 49
50 -5 13 -2 17 13 17 47 0 61 -73 22 -115 -35 -37 -97 -35 -134 4 -77 80 -34
219 78 249 65 17 100 15 143 -9 149 -82 199 -249 92 -304 -37 -19 -36 -49 2
-53 29 -3 89 40 89 63 0 8 10 21 22 29 18 13 144 15 1009 16 l988 0 25 -43
c26 -44 59 -67 97 -67 28 0 37 31 14 44 -11 6 -32 23 -47 38 -30 29 -38 82
-18 133 14 37 98 124 142 146 39 20 106 25 136 11 63 -32 80 -45 106 -82 18
-26 32 -38 38 -32 31 31 -58 130 -137 152 -27 8 -70 10 -107 7 -74 -7 -122
-37 -185 -117 -24 -30 -57 -72 -74 -92 l-30 -38 -949 0 -949 0 -38 47 c-109
134 -170 184 -243 198 -19 3 -42 8 -50 10 -8 2 -39 -3 -68 -11z"
					/>
					<path
						d="M3556 873 c-64 -19 -112 -56 -137 -106 -32 -62 -9 -64 43 -4 48 55
92 77 157 77 74 0 184 -90 217 -177 23 -59 5 -117 -44 -146 -35 -21 -40 -49
-8 -55 29 -6 66 19 93 60 19 32 29 38 57 38 36 0 46 -12 46 -56 0 -61 101
-208 166 -240 56 -27 174 -25 229 3 150 79 170 271 38 367 -40 30 -56 36 -100
36 -49 0 -56 -3 -93 -40 -36 -36 -40 -45 -40 -88 0 -54 18 -85 62 -108 45 -23
94 -11 114 28 13 25 14 35 4 54 -7 12 -21 25 -32 27 -11 3 -17 10 -13 16 11
19 43 12 64 -15 44 -56 14 -151 -56 -181 -142 -59 -293 63 -293 236 0 100 44
179 119 213 37 17 56 19 113 14 76 -8 88 2 32 29 -61 29 -178 3 -238 -53 -37
-36 -76 -97 -76 -121 0 -38 -11 -51 -46 -51 -37 0 -48 24 -16 32 22 6 37 94
22 133 -16 42 -80 62 -80 26 0 -31 -20 -35 -48 -12 -40 32 -122 68 -167 74
-22 2 -62 -2 -89 -10z"
					/>
					<path
						d="M5400 875 c-50 -16 -110 -46 -110 -56 0 -5 -45 -29 -55 -29 -2 0 -5
10 -7 22 -4 30 -29 33 -61 8 -24 -19 -27 -28 -27 -80 0 -49 3 -61 20 -70 33
-18 25 -42 -12 -38 -30 3 -34 7 -46 53 -28 99 -99 160 -206 176 -51 8 -71 7
-100 -5 -59 -25 -45 -38 33 -30 53 5 76 3 103 -10 75 -36 128 -125 128 -215 0
-102 -82 -219 -172 -246 -65 -19 -115 -7 -165 38 -30 28 -35 39 -35 79 0 50
17 85 44 94 33 10 40 -7 13 -31 -32 -30 -32 -59 0 -90 59 -60 165 5 165 99 0
31 -47 99 -78 114 -14 6 -45 12 -69 12 -35 0 -51 -7 -95 -43 -50 -41 -68 -70
-83 -140 -4 -20 -10 -27 -20 -23 -18 6 -45 -3 -45 -15 0 -5 14 -9 30 -9 20 0
32 -6 36 -17 28 -88 75 -138 158 -166 102 -35 191 -13 260 64 52 58 106 160
106 200 0 55 83 62 95 8 8 -36 56 -71 92 -67 39 4 36 31 -7 60 -71 48 -64 143
16 228 61 66 123 94 188 86 59 -7 116 -42 146 -90 35 -58 57 -38 26 23 -45 86
-171 136 -266 106z"
					/>
					<path
						d="M8507 879 c-84 -20 -132 -56 -223 -169 l-65 -80 -948 0 -949 0 -28
33 c-16 18 -50 61 -76 95 -26 34 -66 74 -90 89 -37 25 -51 28 -122 28 -64 0
-90 -5 -122 -22 -47 -25 -91 -73 -100 -108 -11 -46 7 -45 39 2 52 77 132 107
219 83 41 -12 131 -88 153 -130 39 -76 24 -154 -35 -185 -51 -26 -31 -65 25
-51 27 7 60 39 79 78 l12 23 936 2 c984 2 1078 0 1078 -24 0 -8 16 -31 35 -50
26 -26 41 -34 62 -31 39 4 36 31 -7 60 -71 48 -64 143 17 229 84 89 168 110
260 62 56 -29 83 -75 83 -139 0 -46 -4 -57 -34 -88 -43 -46 -99 -50 -136 -11
-39 42 -25 115 21 115 22 0 23 -4 8 -32 -16 -31 11 -56 45 -40 48 21 39 83
-15 112 -43 24 -114 -19 -125 -74 -13 -71 45 -136 125 -143 137 -12 202 180
100 295 -51 56 -150 88 -222 71z"
					/>
					<path
						d="M4522 783 c-3 -10 -6 -37 -6 -60 1 -24 -2 -43 -6 -43 -3 0 -25 20
-47 45 -34 38 -46 45 -77 45 -42 0 -80 -16 -71 -31 4 -5 14 -9 24 -9 9 0 30
-14 46 -30 16 -17 36 -30 45 -29 51 5 75 -23 95 -111 15 -66 37 -60 53 15 7
33 13 68 13 78 0 14 9 17 46 17 36 0 49 5 65 25 14 18 29 25 54 25 42 0 44 14
3 35 -40 21 -82 12 -122 -26 -37 -36 -51 -33 -55 12 -6 55 -44 81 -60 42z"
					/>
					<path
						d="M3079 717 c-44 -29 -56 -64 -40 -116 20 -69 112 -110 178 -82 38 17
75 66 71 93 -3 21 -6 19 -45 -20 -34 -35 -48 -42 -80 -42 -48 0 -79 21 -88 60
-8 37 16 80 45 80 23 0 24 -4 8 -30 -17 -26 10 -55 41 -45 34 10 44 40 26 75
-28 54 -64 62 -116 27z"
					/>
					<path
						d="M3550 733 c-23 -8 -50 -45 -50 -69 0 -24 29 -54 53 -54 20 0 33 38
18 53 -18 18 -13 27 14 27 47 0 62 -80 22 -118 -41 -39 -95 -34 -138 13 -19
19 -39 35 -46 35 -23 0 -14 -29 20 -65 91 -100 252 -18 222 112 -9 43 -74 80
-115 66z"
					/>
					<path
						d="M5470 722 c-108 -54 -48 -212 81 -212 59 0 119 51 119 101 0 19 -5
17 -45 -20 -37 -34 -52 -41 -85 -41 -51 0 -80 27 -80 74 0 50 9 66 36 66 27 0
28 -3 13 -32 -8 -16 -8 -24 4 -35 18 -18 53 -9 67 18 17 32 -28 99 -67 99 -4
0 -24 -8 -43 -18z"
					/>
					<path
						d="M5900 710 c-34 -37 -38 -60 -12 -83 36 -33 84 -8 63 32 -15 28 -14
31 13 31 18 0 27 -8 35 -30 15 -43 -3 -85 -44 -104 -30 -15 -38 -15 -68 -3
-18 8 -43 26 -54 41 -38 47 -60 22 -27 -30 31 -47 99 -67 156 -45 85 32 113
151 46 195 -49 32 -76 31 -108 -4z"
					/>
					<path
						d="M3242 674 c-34 -15 11 -24 114 -24 88 0 115 3 112 13 -3 8 -36 13
-109 14 -57 1 -110 -1 -117 -3z"
					/>
					<path
						d="M5617 674 c-17 -18 14 -24 114 -24 90 0 109 3 109 15 0 12 -19 15
-108 15 -60 0 -112 -3 -115 -6z"
					/>
				</g>
			</svg>
		</motion.div>
	);
}
