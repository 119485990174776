/* eslint-disable consistent-return */
import { useEffect } from 'react';
import { motion } from 'framer-motion';
import Slider from 'react-slick';
import styles from './Intro.module.css';
import ButtonScrollBotton from '../ui/button-nav/ButtonNav';

interface IIntroProps {
	images: string[];
	text?: string;
	textLarge?: string;
	onClick: VoidFunction;
}
export default function Intro({
	images,
	text,
	textLarge,
	onClick,
}: IIntroProps) {
	const settings = {
		dots: false,
		fade: true,
		infinite: true,
		speed: 1000,
		slidesToShow: 1,
		slidesToScroll: 1,
		waitForAnimate: false,
		autoplay: true,
		autoplaySpeed: 5000,
		arrows: false,
	};

	useEffect(() => {
		const setViewHeight = () => {
			document.documentElement.style.setProperty(
				'--vh',
				`${window.innerHeight * 0.01}px`
			);
		};
		setViewHeight();
		window.addEventListener('resize', setViewHeight);
		return () => {
			window.removeEventListener('resize', setViewHeight);
		};
	}, []);

	return (
		<section className={styles.wrapper}>
			<Slider {...settings}>
				{images.map((img: string) => {
					return (
						<div key={img}>
							<img
								src={img}
								alt="Картинки из Якутии"
								className={styles.image}
							/>
						</div>
					);
				})}
			</Slider>
			<motion.h1
				initial={{ left: '-50%' }}
				animate={{ left: '50%' }}
				transition={{ duration: 1, delay: 1 }}
				className={styles.title}
			>
				{text} <br /> <span className={styles.title_large}>{textLarge}</span>
			</motion.h1>
			<ButtonScrollBotton
				onClick={onClick}
				borderRadius="10px"
				rotate="0deg"
				opacity={0.6}
			/>
		</section>
	);
}
