export const images = [
	'https://i.postimg.cc/sfNfLyhJ/1640858891-29-celes-club-p-oimyakon-zima-priroda-krasivo-foto-35.jpg',
	'https://i.postimg.cc/HkRBv60x/1640831579-66-celes-club-p-priroda-yakutii-zimoi-priroda-krasivo-foto-78.jpg',
	'https://i.postimg.cc/HLxQTd6k/aa859a34bf7475b05f8e106b92cd8cf9.jpg',
	'https://i.postimg.cc/bwLxXsH5/1646721567-48-sportishka-com-p-tundra-zimoi-i-letom-turizm-krasivo-foto-54.jpg',
];

export const factsGallery = [
	'https://i.postimg.cc/NFw92ckT/124341.jpg',
	'https://i.postimg.cc/CLTHbyxc/davydovagalina6533-80032710-147820549987965-8155857056283111211-n.jpg',
	'https://i.postimg.cc/cLdR5QYY/15724557-0-0-1274-720-2072x0-60-0-0-2d47a5927db890df5c2faf45c3067bcd.jpg',
	'https://i.postimg.cc/ZYyx9Y49/nOgJgLoY.jpg',
];

export const tourGallery = [
	{
		id: 1,
		path: 'oymyakon',
		image:
			'https://i.postimg.cc/nrHGcbRh/7658a750-7ad9-4148-b2c6-4f9a4351d0c6-1024x1280.jpg',
		image_intro:
			'https://i.postimg.cc/9QHXYp6P/1702175870-sportishka-com-p-priroda-yakutii-zimoi-pinterest-2.jpg',
		title: 'Путешествие в Оймякон (Полюс холода)',
		title_short: 'Путешествие в Оймякон',
		subtitle:
			'Оймякон  — самое холодное место на  Земле, где круглый год живут  люди. Зимой температура здесь  опускается до -60°C',
		price: '130 000 руб.',
		dates: [
			'30.12.2024 – 02.01.2025',
			'04.01.2025 – 08.01.2025',
			'14.01.2025 – 28.01.2025',
			'20.01.2025 – 24.01.2025',
			'01.02.2025 - 04.02.2025',
			'10.02.2025 - 14.02.2025',
			'20.02.2025 - 24.02.2025',
			'05.03.2025 - 09.03.2025',
		],
		duration: '4 дня',
		program: [
			`Путь до  Хандыги 09.00 ч - сбор на автовокзале (ул.Октябрьская, 24). Выезд из города Маршрут движения проходит по знаменитой трассе "Колыма" В пути вас ждут две ледовые переправы через реки Лена (15 км) и Алдан (1,5 км). Через 100 км остановка для обеда в придорожном кафе поселка Тенгюлю. Эта часть дороги богата скульптурами, которые расположились вдоль автодороги. Более известными скульптурами являются скульптуры герою якутского эпоса богатырю Нюргун Ботур и мальчику рыбаку, где мы сделаем остановку. Поселок Хандыга — это центр промышленного Томпонского улуса. С реки Алдан, на которой расположен поселок, начинается бывшая территория «Дальстроя». Прибыв в Хандыгу, вы посетите местный музей, в котором есть экспозиция, посвященная узникам ГУЛАГа. На ночь вы разместитесь в гостинице поселка. Протяженность автомаршрута 432 км, 8-9 часов в пути.`,
			'Дорога  в горах. В гостях у оленеводов. Путь до Томтора 7.30 ч - подъем, завтрак 9.00 ч - выезд С поселка Хандыги начинается дорога, которая строилась узниками лагерей ГУЛАГ в 40-50-ые годы XX века. В эти годы вдоль дороги через каждые 20-30 км располагались лагеря. Маршрут проходит через хребты Томпорукский, Скальные Горы и Сунтар Хаята, петляя среди высоких гор. Горные перевалы на этом участке: Томпорунский, Черный Прижим, Алдано-Индигирский В пути остановки в живописных местах для фотосъемки. В долине речки Кюбяма заезд к оленеводческой бригаде. Катание на оленьих упряжках, знакомство с бытом оленеводов. Обед, где предложим блюда из оленины. В селе Томтор размещение в гостевом доме. Гостевом доме удобства находятся внутри дома. Есть баня. Протяженность автомаршрута 481 км, 10-12 часов в пути. ',
			'Экскурсии  в Оймяконе и Томторе 07.30 ч - подъем, завтрак 09.00 ч - выезд в поселок Оймякон. Время пути до Оймякона 1 час. В поселке Оймякон в настоящее время находится метеопункт. Так как Оймякон расположен ниже по уровню моря чем Томтор, в Оймяконской низине концентрируется воздух, холодней чем в Томторе. Программа в Оймяконе: - фотографирование у стелы Холода - получение сертификата посещения Полюса Холода - Оймякон 12.00 ч - обед. - эксперименты с холодом. 16.00 ч. - Выезд в Томтор. Программа в Томторе: - посещение музея села. Музей богат материалами и экспонатами времен ГУЛАГ. - посещение Подземной резиденции Повелителя Холода - Чысхана 19.30 ч. - возвращение в гостевой дом, ужин. После ужина баня. Протяженность автомаршрута 80 км',
			'Путь  до Якутска 6.00 ч - подъем, завтрак 7.00 ч - выезд В пути остановки для  приема пищи в придорожных  кафе Приезд в Якутск к 24.00 часам  По приезду в Якутск развоз по гостиницам. Длина дневного маршрута 913 километра. По приезду в Якутск развоз по гостиницам. По желанию туристов мы можем организовать выезд на Ленские Столбы и однодневную экскурсию в Якутске',
		],
		gallery: [
			'https://i.postimg.cc/25Zw0xLr/d6f2165c4c69115c5ba89c8d1dda8d60.jpg',
			'https://i.postimg.cc/xCGP2TPZ/scale-1200.jpg',
			'https://i.postimg.cc/3xW9vhKy/9334902-original.jpg',
			'https://i.postimg.cc/593mKyyY/5d5bdd1585600a1c3e6d78d5.jpg',
			'https://i.postimg.cc/dtVjbFZW/Polyus-KHoloda-9.jpg',
			'https://i.postimg.cc/sDTwxxRd/festival-polus-kholoda-oimyakon-2020-30-1565176268.jpg',
		],
	},

	{
		id: 2,
		path: 'deers',
		image: 'https://i.postimg.cc/J4VbKpsP/severnyj-olen43.webp',
		image_intro:
			'https://i.postimg.cc/HLxQTd6k/aa859a34bf7475b05f8e106b92cd8cf9.jpg',
		title: 'В гостях у оленеводов',
		title_short: 'В гостях у оленеводов',
		subtitle:
			'Этнографический  тур в стойбище оленеводов. Знакомство  с бытом, национальной кухней, погружение в тонкости оленеводства, катанье на упряжках.',
		price: '70 000 руб.',
		dates: [
			'25.12.2024 – 27.12.2024',
			'03.01.2024 – 05.01.2025',
			'16.01.2025 – 18.01.2025',
			'22.01.2025 – 24.01.2025',
			'28.01.2025 – 30.01.2025',
			'02.02.2025 - 04.02.2025',
			'08.02.2025 - 10.02.2025',
			'18.02.2025 - 20.02.2025',
			'03.03.2025 - 05.03.2025',
			'08.03.2025 - 10.03.2025',
			'15.03.2025 - 17.03.2025',
		],
		duration: '2 дня',
		program: [
			'Выезд  в стойбище оленеводов  (в зависимости от того, где сейчас стоянка — расстояние от Якутска может быть порядка 60-90 км). Прибыв на место, познакомитесь с семьей и разместитесь в чуме — традиционном жилище кочевых народов Севера. Хозяйка угостит вас обедом с блюдами национальной кухни. Вам расскажут об особенностях чума — он устроен очень просто и идеально подходит для кочевого образа жизни. Здесь нет комнат или перегородок, а обстановка минималистична. Лишних вещей или предметов мебели вы здесь не увидите, поскольку всё это добавляет лишний вес при переездах. В зимнее время чум укрывается шкурами оленей и прогревается днем печкой-буржуйкой. Благодаря этому внутри тепло даже в 40-градусные морозы. Вечером вы поужинаете и отдохнете. Если погода будет ясной, сможете устроить фотоохоту на северное сияние. Ночевка в комфортном чуме.',
			'После  завтрака вы на оленьей упряжке  поедете к стаду оленей. Сегодня  вы погрузитесь в быт оленеводов  и понаблюдаете за их ежедневной  обязанностью — отловом ездовых  оленей. Оленеводы покажут вам, как  заарканить оленя, правильно собрать  упряжку и управлять ею. При  желании вы сможете попробовать себя в роли каюра — погонщика оленей. После обеда у вас будет свободное время. По возможности для вас организуют образовательный вечер: оленеводы расскажут вам о своих традициях, верованиях и духовной культуре. Если позволит погода и планы оленеводов, возможен выезд на подледную рыбалку. Возвращение в Якутск. По приезду в Якутск развоз по гостиницам. По приезду в Якутск развоз по гостиницам. По желанию туристов мы можем организовать посещение ресторана традиционной кухни.',
		],
		gallery: [
			'https://i.postimg.cc/PJh5vvqz/5.jpg',
			'https://i.postimg.cc/CKB13VtD/690a7a99c6ae4aefae27b84e92c71a55-max-1200x800.jpg',
			'https://i.postimg.cc/K8Y45TD0/1640858884-27-celes-club-p-oimyakon-zima-priroda-krasivo-foto-33.jpg',
			'https://i.postimg.cc/XNwZN4vk/reindeer-1.jpg',
			'https://i.postimg.cc/J4VbKpsP/severnyj-olen43.webp',
			'https://i.postimg.cc/nrv96tXT/1679651616-celes-club-p-yakutiya-oleni-vkontakte-47.jpg',
		],
	},

	{
		id: 3,
		path: 'lena-pillars',
		image:
			'https://i.postimg.cc/13f6w1jd/1693962860-happylove-top-p-yakutiya-stolbi-lenskie-vkontakte-20.jpg',
		image_intro:
			'https://i.postimg.cc/13f6w1jd/1693962860-happylove-top-p-yakutiya-stolbi-lenskie-vkontakte-20.jpg',
		title: 'Ленские столбы',
		title_short: 'Ленские столбы',
		subtitle:
			'Посещение национального парка «Ленские столбы», представляющего собой скальные образования, высота которых доходит до 220 метров. ',
		price: '30 000 руб.',
		dates: [
			'30.12.2024 ',
			'04.01.2025',
			'07.01.2025',
			'09.01.2025',
			'12.01.2025',
			'17.01.2025',
			'21.01.2025',
			'29.01.2025',
			'02.02.2025 ',
			'05.02.2025',
			'10.02.2025',
			'14.02.2025',
			'20.02.2025',
			'24.02.2025',
			'08.03.2025 ',
			'09.03.2025',
		],
		duration: '1 день',
		program: [
			`Выезд из Якутска в 09.00. По пути вы будете делать остановки, чтобы осмотреть наскальные рисунки на скалах Эмэгэттэх, Еланковская и Арка Желаний. Прибыв на место, вы подниметесь на смотровую площадку «Лабыдья», откуда открываются великолепные виды на покрытые льдом просторы реки Лена и «охраняющих» ее скал. На маршруте у вас будет обед-перекус (ланч-бокс), а поужинаете вы самостоятельно в придорожном кафе. В 22:00 возвращение в гостиницу Якутска`,
		],
		gallery: [
			'https://i.postimg.cc/C5cBncHY/091b3fe96112f76bd24e62b1038fcb75.jpg',
			'https://i.postimg.cc/J4dH1dz5/lenskie-stolbi-zimoi-46.jpg',
			'https://i.postimg.cc/HWz7NLGK/1648677250-12-vsegda-pomnim-com-p-reka-lena-zimoi-foto-13.jpg',
			'https://i.postimg.cc/SNKzbpkK/IMG-20200826-WA0036.jpg',
			'https://i.postimg.cc/MpMMXbc2/100-1.jpg',
			'https://i.postimg.cc/JzvXQdwz/368480a2140465350f172947e5c2ec31.jpg',
		],
	},

	{
		id: 4,
		path: 'yakutsk-magadan',
		image:
			'https://i.postimg.cc/4dBbZMQG/1679761087-celes-club-p-doroga-zimoi-pinterest-43.jpg',
		image_intro: 'https://i.postimg.cc/8CdmXsFj/original.jpg',
		title: 'Якутск — ГУЛАГ — Оймякон — Магадан',
		title_short: 'Якутск — Магадан',
		subtitle:
			'Экстремальный экспедиционный тур по легендарной Колымской трассе. Заезд в Оймякон – Полюс холода',
		price: '250 000 руб.',
		dates: [
			'30.12.2024 – 06.01.2025',
			'04.01.2025 – 12.01.2025',
			'14.01.2025 – 22.01.2025',
			'20.01.2025 – 28.01.2025',
			'01.02.2025 - 08.02.2025',
			'10.02.2025 - 18.02.2025',
			'20.02.2025 - 28.02.2025',
			'02.03.2025 - 09.03.2025',
		],
		duration: '8 дней',
		program: [
			` 09:00 – выезд из Якутска. Маршрут движения проходит по знаменитой трассе "Колыма". В пути вас ждут две ледовые переправы через реки Лена (15 км) и Алдан (1,5 км). Через 100 км остановка для обеда (самостоятельно, оплата на месте по меню) в придорожном кафе поселка Тенгюлю. Эта часть дороги богата скульптурами, которые расположились вдоль автодороги — это скульптура герою якутского эпоса богатырю Нюргун Ботур, скульптура "Собрание птиц" и другие. Поселок Хандыга — это центр промышленного Томпонского улуса. С реки Алдан, на которой расположен поселок, начинается бывшая территория «Дальстроя». Прибыв в Хандыгу, вы посетите местный музей, в котором есть экспозиция, посвященная узникам ГУЛАГа. Размещение в гостинице. Ужин. Протяженность автомаршрута 432 км, 8-9 часов в пути. `,
			`Выезд с поселка Хандыга. Наш путь начинается с дороги, которая строилась узниками лагерей ГУЛАГ в 40-50-ые годы XX века. В эти годы вдоль дороги через каждые 20-30 км располагались лагеря. Маршрут проходит через хребты Томпорукский, Скальные Горы и Сунтар Хаята, петляя среди высоких гор. Горные перевалы на этом участке: Томпорунский, Черный Прижим, Алдано-Индигирский. По пути будут остановки в самых живописных местах для фотосъемки. Обед-перекус (ланч-боксы). Ужин. Отдых.`,
			`Выезд в поселок Оймякон. Время пути до Оймякона 1 час. Программа в Оймяконе: фотографирование у стелы Холода; получение сертификата посещения Полюса Холода - Оймякон. 12:00 ч — обед. Экскурсия в Оймяконской общеобразовательной школе, этно-программа учащихся школы, эксперименты с холодом. Эксперименты на морозе. 16:00 ч. — выезд в Томтор. Программа в Томторе: посещение музея села. Музей богат материалами и экспонатами времен ГУЛАГ. Посещение Подземной резиденции Повелителя Холода — Чысхана. 19.30 ч. — возвращение в гостевой дом, ужин. После ужина баня. Протяженность автомаршрута 80 км.`,
			`Переезд до Усть-Неры. Ожидаемая температура днём от –40C до –50C. Выезд из Томтора (время выезда зависит от готовности участников и автомашин) в Усть-Неру. Усть-Нера — это «золотая» столица северо-восточной части Якутии. От поселка и почти до Магадана вдоль дороги расположились многочисленные золотые прииски. В пути остановка для катания на оленьих упряжках. Горные перевалы на этом участке: Брюнгядинский, Ольчанский. Обед-перекус (ланч-боксы). Прибытие в Усть-Неру. Размещение в отеле Усть-Неры (двухместные номера с удобствами, гостиница советского образца, двухместное размещение). Ужин. Протяженность автомаршрута — 393 км.`,
			`Переезд до поселка Ягодный. Завтрак. В пути дорога пересекает границу Якутии и Магаданской области. Вечером в поселке Ягодный посещение частного музея истории ГУЛАГ Ивана Паникарова. Обед-перекус (ланч-боксы). Горные перевалы на этом участке: Колымо-Индигирский, Архагалинский и Бурхалинский. Ужин. Отдых. Протяженность автомаршрута — 505 км.`,
			`п. Ягодный – Магадан Переезд в Магадан. Протяженность автомаршрута — 525 км. Ожидаемая температура днем от –35C до –20C. Завтрак. В этот день предстоит переезд с Ягодного в Магадан. Пересечение одной из крупнейших рек Якутии — Колыму. В городе размещение в гостевом доме (или за дополнительную плату размещение в гостинице). Горные перевалы на этом участке: Гербинский, Дедушкино Лысина, Черноозерский, Яблоневый, Карамкенский. На этом участке находится много придорожных кафе (за доплату). Дорога хорошая. Приезд в Магадан. Размещение. Ужин. Отдых.`,
			`Магадан Экскурсионный день в Магадане. Завтрак. В этот день вы увидите мемориал «Маска скорби», посвященный памяти жертв политических репрессий; посетите Краеведческий музей и музей Естественной Истории; прогуляетесь по побережью Нагаевской бухты, где в эпоху сталинских репрессий прибывали пароходы с заключенными, следовавшими по этапу в лагеря Магадана и Колымы. Пообедать путешественники могут в центре города, в кафе, расположенном в районе бывшего здания Администрации «Дальстроя» (за доплату). Свободное время. Ужин. Отдых.`,
			`Заключительный день тура. Отъезд в аэропорт. Завтрак.Групповой трансфер в аэропорт. Вылет из Магадана.`,
		],
		gallery: [
			'https://i.postimg.cc/QtXk2s5S/wr-960.webp',
			'https://i.postimg.cc/8CdmXsFj/original.jpg',
			'https://i.postimg.cc/JhCQQjzf/gallery-793-39-208511.jpg',
			'https://i.postimg.cc/pVsqt9sj/90534.jpg',
			'https://i.postimg.cc/NFjDHYjL/scale-1200.jpg',
		],
	},

	{
		id: 5,
		path: 'hourses',
		image:
			'https://i.postimg.cc/j5WbKVz0/db33595fb3923fab3a3ef45cdbc72df7ac8395fdr1-793-505v2-hq.jpg',
		image_intro: 'https://i.postimg.cc/Sxqk9vpC/IMG-4304-2-1024x683.jpg',
		title: 'Конные прогулки',
		title_short: 'Конные прогулки',
		subtitle:
			' Якутская лошадь считается одной из самых древних пород на нашей планете. Это уникальное животное, способное выжить при температуре -60 С градусов',
		price: '10 000 руб.',
		dates: [
			'30.12.2024 ',
			'02.01.2025',
			'03.01.2025',
			'05.01.2025',
			'07.01.2025',
			'09.01.2025',
			'12.01.2025',
			'17.01.2025',
			'21.01.2025',
			'29.01.2025',
			'02.02.2025 ',
			'05.02.2025',
			'10.02.2025',
			'14.02.2025',
			'20.02.2025',
			' 24.02.2025',
			'08.03.2025 ',
			'09.03.2025',
		],
		duration: '4 часа',
		program: [
			` Выезд из Якутска на конную базу. Время в пути 1,5 часа. Знакомство с бытом коневодов. Прогулки верхом или в санях на выбор. Дегустация блюд из жеребятины. Якутские лошади уникальны: они живут при температуре -60-70 градусов в условиях, где световой день зимой длится всего три часа. У этих животных очень плотная шерсть, что и помогает выжить при экстремально низких температурах. Жителей республики Саха (Якутия) эти лошади снабжают и молоком, и мясом, а также часть их становится ездовыми. Интересно, что лошади питаются подножным кормом, находя растительность глубоко под снегом. Возвращение в Якутск в 22:00`,
		],
		gallery: [
			'https://i.postimg.cc/MZCW6GPs/1545322597-5.jpg',
			'https://i.postimg.cc/Kjgspxtj/3280471a42a891496f51974a45a0bae4.jpg',
			'https://i.postimg.cc/s2gz6Gx2/5f9be3fa85600a4437735aab.jpg',
			'https://i.postimg.cc/TPN6Z25J/1696033333-pibig-info-p-oimyakon-gori-instagram-85.jpg',
			'https://i.postimg.cc/0279bqh9/1637323386-54-celes-club-p-yakutskaya-loshad-zhivotnie-krasivo-foto-55.jpg',
			'https://i.postimg.cc/FHZmVV9q/maxresdefault.jpg',
			'https://i.postimg.cc/HWcY1M2c/1603737843-02.jpg',
		],
	},

	{
		id: 6,
		path: 'chum',
		image:
			'https://i.postimg.cc/QCJsYQQH/nordlicht-yukon-ara-adav-20160214-010224-7580.jpg',
		image_intro:
			'https://i.postimg.cc/mZYbrsvB/1646721567-48-sportishka-com-p-tundra-zimoi-i-letom-turizm-krasivo-foto-54.jpg',
		title: 'Один день в чуме',
		title_short: 'Один день в чуме',
		subtitle:
			' Приглашаем вас на несколько дней погрузиться в жизнь кочевников с возможностью увидеть северное сияние',
		price: '30 000 руб.',
		dates: [
			'31.12.2024 ',
			'03.01.2025',
			'07.01.2025',
			' 09.01.2025',
			'13.01.2025',
			'16.01.2025',
			'22.01.2025',
			' 31.01.2025',
			'02.02.2025 ',
			'05.02.2025',
			'10.02.2025',
			'14.02.2025',
			'21.02.2025',
			' 25.02.2025',
			'08.03.2025 ',
			'09.03.2025',
		],
		duration: '1 сутки',
		program: [
			'Выезд из Якутска в 9:00. Путь то стоянки в тайге - 3 часа. Быт оленеводов остается неизменным уже несколько сотен лет: будьте готовы к отсутствию привычного уровня комфорта. Вы будете жить в чуме вместе с семьей кочевников, спать на постелях из оленьих шкур и питаться блюдами местной кухни, приготовленными на печи или костре. На следующий день завтрак в 8:00, возвращение в Якутск в 12:00',
		],
		gallery: [
			'https://i.postimg.cc/HngdtZ7q/ku29q4q8m8hhtgdokp9xwd1s07aantad.jpg',
			'https://i.postimg.cc/vHGGgKy9/ae0e5b33cd060b01597763139.jpg',
			'https://i.postimg.cc/NjMYbr5j/561634-chum-15.jpg',
			'https://i.postimg.cc/FsGNjxF6/J45A9621.jpg',
			'https://i.postimg.cc/rmWqxXzG/scale-1200.png',
			'https://i.postimg.cc/HLFprsWs/73b195c8bbdad4177153aaaa661eab4a.jpg',
		],
	},
];

export const tourConditions = {
	included: [
		'встреча в аэропорту в день начала тура',
		'все трансферы, связанные с туром',
		'все экскурсии, включенные в программу тура',
		'проживание и питание в гостевых домах поселков Хандыга, Оймякон, Томтор, Усть-Нера, Ягодный, Магадан',
		'услуги гида-проводника',
	],
	excluded: [
		'авиа- и ж/д билеты до г. Якутска и обратно из г. Магадана',
		'размещение в гостинице Якутска',
		'размещение в гостинице Магадана (при желании замены гостевого дома (проживание включено в стоимость тура) на гостиницу)',
		'встреча и трансфер до гостиницы, если прилет не в день отъезда в тур',
		'трансфер из гостиницы до аэропорта',
		'питание в придорожных кафе во время трансфера до точки начала похода',
		'аренда зимних курток и обуви',
	],
};
